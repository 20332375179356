import qs from 'qs';
import { IS_DEVELOPMENT } from '../config/environment';
import {
  HEADER_ACCEPT,
  HEADER_APP_NAME,
  HEADER_APP_VERSION,
  HEADER_AUTHORIZATION,
  HEADER_CONTENT_TYPE,
} from '../config/headers';
import { FetchError } from './errors';

export const fetchHeaders = {
  [HEADER_ACCEPT]: 'application/json',
  [HEADER_CONTENT_TYPE]: 'application/json',
};

export interface FetchRequestOptions {
  catchErrors?: boolean;
  data?: unknown;
  method: RequestInit['method'];
  url: string;
}

/**
 * @name fetchRequest
 * @description tbd...
 */
export const fetchRequest = async (
  accessToken: string,
  options: FetchRequestOptions,
  baseUrl: string,
  headers: {
    [HEADER_APP_NAME]: string;
    [HEADER_APP_VERSION]: string;
  } & Record<string, string> = {
    [HEADER_APP_NAME]: '',
    [HEADER_APP_VERSION]: '',
  },
) => {
  const { catchErrors, data, method, url } = options;

  let params = '';

  const headersRequest: RequestInit['headers'] = {
    ...fetchHeaders,
    ...headers,
  };

  if (accessToken) {
    headersRequest[HEADER_AUTHORIZATION] = `Bearer ${accessToken}`;
  }

  const request: RequestInit = { headers: headersRequest, method };

  if ((method === 'POST' || method === 'PATCH') && data) {
    request.body = JSON.stringify(data);
  } else if ((method === 'GET' || method === 'DELETE') && data) {
    params += '?';
    params += qs.stringify(data, { arrayFormat: 'brackets' });
  }

  /**
   * We can set a timeout for the fetch request
   */
  const timeout = process.env.GLOBAL_FETCH_TIMEOUT_MS
    ? parseInt(process.env.GLOBAL_FETCH_TIMEOUT_MS, 10)
    : 30000; // A default of 30 seconds

  // request.signal = AbortSignal.timeout(timeout);

  try {
    const path = `${baseUrl}${url}${params}`;
    const response = await fetch(path, request);
    const json = await response.json();

    if (catchErrors && json?.code && json?.message) {
      const error = new FetchError(
        json.message,
        json.code,
        json.name,
        json.data,
      );

      if (IS_DEVELOPMENT) console.error(`🪫  Fetch failed`, json);

      throw error;
    }

    return json;
  } catch (error) {
    const isError = error instanceof Error;

    // If it's an abort error, we throw a custom error
    if (isError && error.message === 'The operation was aborted.') {
      const amount = timeout / 1000;
      const msg = `Global request timeout of "${amount}" seconds exceeded. Please try again.`;
      throw new Error(msg);
    }

    throw error;
  }
};
