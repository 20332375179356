import { NavLink, NavLinkProps } from '@remix-run/react';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useKeyPress } from '@shiftsmartinc/react-hooks';
import { ArrowLineLeft, ArrowLineRight } from '@phosphor-icons/react';

export interface SidebarLink extends NavLinkProps {
  icon?: JSX.Element;
  title: string;
}

export interface SidebarProps {
  className?: string;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  isCollapsed?: boolean;
  links: SidebarLink[];
  onToggleCollapse?: (collapsed: boolean) => void;
}

/**
 * @name Sidebar
 * @description A sidebar navigation component that displays links composed
 * by an icon and a title. Supports navigation by wrapping NavLink from Remix.
 * Accepts a header and a footer.
 */
export const Sidebar = (props: SidebarProps) => {
  const {
    className,
    isCollapsed = false,
    footer,
    header,
    links,
    onToggleCollapse,
  } = props;

  // Hooks
  const [collapsed, setCollapsed] = useState(isCollapsed);

  // Setup

  // Handlers
  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
    onToggleCollapse?.(!collapsed);
  };

  // Markup
  const ToggleCollapseArrow = collapsed ? ArrowLineRight : ArrowLineLeft;

  // Life Cycle
  useKeyPress(handleToggleCollapse, '/', true);

  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed]);

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'border-gray-8 flex flex-col justify-between overflow-hidden border-r transition-all duration-300',
        {
          'w-[240px]': !collapsed,
          'w-[72px]': collapsed,
        },
        className,
      )}
    >
      <div
        className={classnames('flex flex-1 flex-col p-6', {
          'w-[240px]': !collapsed,
          'w-[72px]': collapsed,
        })}
      >
        {header}
        <div className="flex flex-1 flex-col justify-between">
          <div className="flex flex-col gap-2">
            {links.map((link) => {
              const {
                className: classNameLink,
                icon,
                title,
                to,
                ...rest
              } = link;

              const pathname = typeof to === 'string' ? to : to.pathname;
              const classes =
                typeof classNameLink === 'string' ? classNameLink : '';

              // No link to navigate to, don't render the markup
              if (!pathname) return null;

              return (
                <NavLink
                  className={({ isActive }) => {
                    return classnames(
                      'font-500 flex flex-row gap-3 whitespace-nowrap py-2 text-sm',
                      {
                        'text-brand': isActive,
                        'text-gray-2': !isActive,
                      },
                      classes,
                    );
                  }}
                  key={pathname}
                  prefetch="intent"
                  to={pathname}
                  {...rest}
                >
                  <div>{icon}</div>
                  <span
                    className={classnames('transition-opacity', {
                      'opacity-0': collapsed,
                      'opacity-100': !collapsed,
                    })}
                  >
                    {title}
                  </span>
                </NavLink>
              );
            })}
          </div>
          {footer}
        </div>
      </div>
      <div
        className="background-main border-gray-8 border-box sticky bottom-0 flex h-4 flex-row items-center justify-center border-t py-8 pt-[31px] hover:cursor-pointer"
        data-testid="toggle-collapse"
        onClick={handleToggleCollapse}
      >
        <ToggleCollapseArrow size={24} />
      </div>
    </div>
  );
};
