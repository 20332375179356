import { Input, InputProps } from '@shiftsmartinc/shiftsmart-ui';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { InferType, Schema } from 'yup';
import { debounce } from 'lodash';
import { useQueryParams } from '../../global/hooks/useQueryParams';

export interface QueryParamTextProps<S extends Schema>
  extends Omit<InputProps, 'id'> {
  id: keyof InferType<S>;
  schema: S;
}

/**
 * @name QueryParamText
 * @description An Input component that controls a query param
 */
export const QueryParamText = <S extends Schema>(
  props: QueryParamTextProps<S>,
) => {
  const { id, schema, ...inputProps } = props;

  // Hooks
  const { params, setParams } = useQueryParams(schema);

  const { [id]: textQueryParam = '', ...otherParams } = params;
  const [text, setText] = useState(textQueryParam);

  // Setup

  // Handlers
  const setTextQueryParam = useCallback(
    debounce((text?: string) => {
      setParams({ [id]: text, page: undefined, ...otherParams });
    }, 200),
    [id, otherParams],
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    setTextQueryParam(event.target.value || undefined);
  };

  // Markup
  const defaultLeftIcon = (
    <MagnifyingGlass className="text-gray-6 absolute top-1/2 mx-2 -translate-y-1/2 transform" />
  );

  // Life Cycle
  useEffect(() => {
    setText(textQueryParam);
  }, [textQueryParam]);

  // 🔌 Short Circuits

  return (
    <Input
      id={String(id)}
      leftIcon={defaultLeftIcon}
      onChange={onChange}
      type="search"
      value={text}
      {...inputProps}
    />
  );
};
