import { NavLink, NavLinkProps } from '@remix-run/react';
import classnames from 'classnames';
import { Tab, TabProps } from '@shiftsmartinc/shiftsmart-ui';

export interface TabsProps {
  className?: string;
  links: (NavLinkProps & Pick<TabProps, 'count' | 'icon' | 'active'>)[];
  orientation?: 'horizontal' | 'vertical';
  size?: 'small' | 'medium' | 'large';
  type?: 'underlined' | 'pill';
}

/**
 * @name Tabs
 * @description A tab bar that wraps NavLink and @shiftsmartinc/shiftsmart-ui Tab components,
 * meant to be used across all of the applications.
 */
export const Tabs = (props: TabsProps) => {
  const {
    className,
    links,
    orientation,
    size = 'medium',
    type = 'underlined',
  } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  const renderTab =
    (link: TabsProps['links'][number]) =>
    // eslint-disable-next-line react/display-name
    (props: { isActive: boolean; isPending: boolean }) => {
      const { children, className, icon, count, active } = link;
      const { isActive, isPending } = props;

      return (
        <Tab
          active={active !== undefined ? active : isActive}
          className={className as string}
          count={count}
          disabled={isPending}
          icon={icon}
          label={children as string}
          orientation={orientation}
          size={size}
          type={type}
        />
      );
    };

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div
      className={classnames(
        'flex',
        { 'flex-col': orientation === 'vertical' },
        className,
      )}
    >
      {links.map((link) => {
        const { to, ...navLinkProps } = link;
        const key = typeof to === 'string' ? to : to.pathname;

        return (
          <NavLink key={key} to={to} {...navLinkProps}>
            {renderTab(link)}
          </NavLink>
        );
      })}
    </div>
  );
};
